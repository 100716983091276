<template>
  <trazo-off-canvas
    :isOpen="isOpen"
    id="modal-form-brand"
    :selectorToggle="selectorToggle"
    v-on:close="closeModal"
    :title="textModal"
  >
    <template v-slot:header-buttons>
      <b-button size="sm" variant="light" @click="closeModal">
        <b-icon icon="arrow-counterclockwise" />
      </b-button>
      <b-button size="sm" variant="light" @click="onSubmit" :disabled="sending">
        {{ sending ? "Guardando..." : "Guardar" }}
      </b-button>
    </template>
    <div class="justify-content-center">

      <form class="full" id="formSubproject" ref="formSubproject">

        <b-alert
          show
          variant="danger"
          v-bind:key="error"
          v-for="error in errors"
          >{{ error }}</b-alert
        >
        <trazo-form-input
          id="input-code"
          v-model="code"
          name="code"
          label="Código"
          :required="true"
          labelPosition="top"
          type="text"
        />
        
        <trazo-form-input
          id="input-abbreviation"
          v-model="abbreviation"
          label="Abreviatura"
          name="abbreviation"
          :required="true"
          labelPosition="top"
          type="text"
        />

        <trazo-form-input
          id="input-description"
          v-model="description"
          label="Descripción"
          name="description"
          :required="true"
          labelPosition="top"
          type="text"
        />

        <b-form-checkbox v-model="is_active" value="1" unchecked-value="0">
          Está activo
        </b-form-checkbox>

      </form>
    </div>
  </trazo-off-canvas>
</template>

<script>
import Vue from "vue";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import ApiService from "@/core/services/api.service";
import TrazoFormInput from "@/view/content/TrazoFormInput";
import TrazoOffCanvas from "@/view/content/TrazoOffCanvas";

function initialState() {
  return {
    textModal: "NUEVO MARCA",
    id: "",
    code: "",
    abbreviation: "",
    description: "",
    is_active: 1,
    edit: false,
    errors: [],
    sending: false
  };
}

export default Vue.component("modal-form-brand", {
  data() {
    return initialState();
  },
  props: ["objForm", "isOpen", "selectorToggle"],
  watch: {
    objForm(newValue, oldValue) {
      if (newValue?.id != oldValue?.id) {
        this.setData();
      }
    }
  },
  methods: {
    closeModal() {
      this.resetForm();
      Object.assign(this.$data, initialState());
      this.$emit("close");
    },
    resetForm() {
      this.setupValidation();
      this.fv.off("core.form.valid", () => {});
      this.fv.off("core.form.invalid", () => {});
      this.fv.resetForm();
    },
    onSubmit() {
      this.resetForm();
      this.fv.validate();
      this.fv.on("core.form.valid", () => {
        const saveFunction = this.id ? ApiService.patch : ApiService.post;
        const objeSaved = {
          code: this.code.trim(),
          abbreviation: this.abbreviation.trim(),
          description: this.description.trim(),
          is_active: this.is_active===1 ? true:false,
        };
        this.errors = [];
        this.sending = true;
        saveFunction(`api/brand/${this.id ? this.id + "/" : ""}`, objeSaved)
          .then(({ data }) => {
            this.$emit("toast", {
              message: `Se ha ${
                this.edit ? "editado" : "creado"
              } el item correctamente`,
              object: {
                title: `Éxito`,
                variant: "success",
                solid: true
              }
            });
            this.$emit(this.edit ? "updated" : "created", data);
            this.closeModal();
            this.sending = false;
          })
          .catch(({ response: { data } }) => {
            this.sending = false;
            Object.entries(data).forEach((object) => {
              this.errors.push(object[1].join(" - "));
              this.fv.updateFieldStatus(object[0], "Invalid");
            });
            this.$emit("toast", {
              message: "Ha ocurrido un error",
              object: {
                title: `Error`,
                variant: "danger",
                solid: true
              }
            });
          });
      });
      this.fv.on("core.form.invalid", () => {
        this.$emit("toast", {
          message: "Por favor complete los campos correctamente.",
          object: {
            title: `Error`,
            variant: "danger",
            solid: true
          }
        });
      });
    },
    setData() {
      if (!this.custom_lodash.isEmpty(this.objForm)) {
        console.log("datos=>",this.objForm);
        this.id = this.objForm.id;
        this.code = this.objForm.code;
        this.description = this.objForm.description;
        this.abbreviation = this.objForm.abbreviation;
        this.is_active = this.objForm.is_active ? 1:0;
        this.textModal = "EDITAR MARCA";
        this.edit = true;
      }
    },
    setupValidation() {
      const formSubproject = KTUtil.getById("formSubproject");
      this.fv = formValidation(formSubproject, {
        fields: {
          code: {
            validators: {
              notEmpty: {
                message: "El código es obligatorio"
              }
            }
          },
          abbreviation: {
            validators: {
              notEmpty: {
                message: "La abreviatura es obligatoria"
              }
            }
          },
          description: {
            validators: {
              notEmpty: {
                message: "La description es obligatoria"
              }
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap()
        }
      });
    }
  },
  mounted() {
    this.setData();
    this.setupValidation();
  },
  components: {
    "trazo-form-input": TrazoFormInput,
    TrazoOffCanvas
  }
});
</script>
