<template>
  <div>
    <portal to="subheader_actions" style="float: right;">
      <b-button @click="openModal" class="mr-2">
        <b-icon icon="plus" aria-label="Help"></b-icon>
        Nuevo
      </b-button>
      <b-button v-if="mostrarFiltro" @click="listarTodo" class="mr-2" >
        <i class="flaticon2-list-2 icon-nm"></i>
        Listar Todo
      </b-button>
      <b-button v-if="mostrarFiltro" @click="openFilter" >
        <b-icon icon="Filter" aria-label="Help"></b-icon>
        Filtrar Por
      </b-button>
    </portal>
    <div v-if="!ocultarChip" class="row">
      <div class="col-md-12">
        <div class="cardChip">
          <filter-chip name="filtroChip" title="Filtros" color="#e1dffc" colorBackground="#f5f8ff" :filtersParent="filtros" :hidden="ocultarChip" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <b-card-text>
            <div>
              <div class="row mb-3 buscador">
                <div class="col ">
                  <b-form-input
                    id="filter-input"
                    v-model="lupa"
                    type="search"
                    placeholder="Escriba para buscar"
                  ></b-form-input>
                </div>
              </div>
              <b-table
                :filter="lupa"
                head-variant="dark"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
              >
              
                <template #cell(status)="data">
                  {{ calculateStatus(data.item.is_active) }}
                </template>

                <template #cell(actions)="data">
                  <div class="h5 mb-0">
                    <b-button
                      size="sm"
                      variant="light"
                      v-b-modal.modal-form-user
                      @click="editItem(data)"
                    >
                      <b-icon icon="pencil-square"></b-icon>
                    </b-button>
                    <b-button
                      @click="removeItem(data)"
                      size="sm"
                      variant="light"
                    >
                      <b-icon icon="trash-fill"></b-icon>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>
          </b-card-text>
          <template v-slot:footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </template>
        </b-card>
      </div>
    </div>
    <modal-form-brand
      :isOpen="showModal"
      v-show="loaded"
      v-on:close="
        showModal = false;
        currentObject = null;
      "
      :objForm="currentObject"
      v-on:updated="onUpdated"
      v-on:created="onCreated"
      v-on:toast="openToast"
    />
    <modal-filter
      class="margen"
      :isOpen="showFilter"
      v-show="loadedFilter"
      @confirmFilter="confirmFilter"
      v-on:close="
        showFilter = false; 
      "
      :filtersParent="filtros"
    />
  </div>
</template>

<script>

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import ModalFormBrand from "./ModalFormBrand";
import ModalFilter from "@/view/layout/filter/Filter";
import FilterChip from "@/view/layout/filter/FilterChip";
import helperFunctions from "@/functions";

export default {
  data() {
    return {
      mostrarFiltro: false,
      //form: { is_active: 1 },
      loaded: false,
      showModal: false,
      currentObject: {},
      currentPage: 1,
      perPage: 12,
      items: [],
      fields: [
        {
          key: "actions",
          sortable: true,
          label: "Acciones"
        },
        {
          key: "code",
          sortable: true,
          label: "Código"
        },
        {
          key: "abbreviation",
          sortable: true,
          label: "Abreviatura"
        },
        {
          key: "description",
          sortable: true,
          label: "Descripción"
        },
        {
          key: "status",
          sortable: true,
          label: "Estado"
        }
      ],
      
      lupa: '',
      showFilter: false,
      loadedFilter: false,
      ocultarChip: true,
      filtros: [
        {
          type: 'text',
          title: 'CÓDIGO',
          alias: 'codigo',
          closable: false,
          expand: false,
          action: false,
          hidden: false,
          options: {
            model: '',
            disabled:  false,
            placeholder: 'Código',
          }
        },
        {
          type: 'text',
          title: 'DESCRIPCIÓN',
          alias: 'descripcion',
          closable: false,
          expand: false,
          action: false,
          hidden: false,
          options: {
            model: '',
            disabled:  false,
            placeholder: 'Descripción',
          }
        },
        {
          type: 'text',
          title: 'ABREVIATURA',
          alias: 'abreviatura',
          closable: false,
          expand: false,
          action: false,
          hidden: false,
          options: {
            model: '',
            disabled:  false,
            placeholder: 'Abreviatura',
          }
        }
      ],

    };
  },
  computed: {
    rows() {
      return this.items.length;
    }
  },
  methods: {
    calculateStatus(status) {
      return status === true ? "ACTIVO" : "INACTIVO";
    },
    openToast({ message, object }) {
      this.$bvToast.toast(message, object);
    },
    editItem(data) {
      this.currentObject = data.item;
      this.openModal();
    },
    openModal() {
      if (!this.loaded)
        setTimeout(() => {
          this.showModal = true;
        }, 50);
      else this.showModal = true;
      this.loaded = true;
    },
    openFilter(){
      if (!this.loadedFilter)
        setTimeout(() => {
          this.showFilter = true;
        }, 50);
      else this.showFilter = true;
      this.loadedFilter = true;
    },

    async confirmFilter(filters) {
      this.showFilter = false
      this.filtros = filters
      this.ocultarChip = helperFunctions.hiddenChipFilter(this.filtros);
      this.listar();
    },

    listarTodo(){
      this.filtros = helperFunctions.filterCleanAll(this.filtros);
      this.ocultarChip = helperFunctions.hiddenChipFilter(this.filtros);
    },

    listar(){
      let obj = helperFunctions.mapFilters(this.filtros);
      console.log("filtros=>",obj);
    },

    removeItem({ item: { id } }) {
      Swal.fire({
        title: "",
        text: "¿Está seguro de eliminar la marca?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonClass: "btn btn-secondary",
        confirmButtonText: "Sí, estoy seguro.",
        confirmButtonClass: "btn btn-secondary",
        cancelButtonText: "Cancelar"
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          ApiService.delete(`/api/brand/${id}/`)
            .then(() => {
              this.openToast({
                message: "Se elimino correctamente el usuario",
                object: {
                  title: `Éxito`,
                  variant: "success",
                  solid: true
                }
              });
              this.items = this.items.filter((el) => el.id !== id);
            })
            .catch(() => {
              this.openToast({
                message: "Ha ocurrido un error",
                object: {
                  title: `Error`,
                  variant: "danger",
                  solid: true
                }
              });
            });
        }
      });
    },
    saveItem() {
      ApiService.post("api/brand/", this.form)
        .then(() => {
          this.showModal = false;
          this.$bvToast.toast("Se ha creado el item correctamente", {
            title: `Éxito`,
            variant: "success",
            solid: true
          });
          this.listItems();
        })
        .catch(() => {
          this.$bvToast.toast("Ha ocurrido un error", {
            title: `Error`,
            variant: "danger",
            solid: true
          });
        });
    },
    listItems() {
      ApiService.get("api/brand", "").then((response) => {
        this.items = response.data;
      });
    },
    onCreated(object) {
      this.items.unshift(object);
    },
    onUpdated(object) {
      this.items = this.items.map((data) => {
        const { id } = data;
        if (id === object.id) {
          return object;
        }
        return data;
      });
    }
  },
  created() {
    this.listItems();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mantenimientos" },
      { title: "Marcas" }
    ]);
  },
  components: {
    ModalFormBrand,
    ModalFilter,
    FilterChip
  }
};
</script>

<style>

.margen{
  margin-top: 5rem;
}
.cardChip{
  margin-bottom: 20px;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: .5rem;
}

.buscador{
  float: right;
}

</style>